import React from 'react';
import { SearchBar } from './SearchBar';

export const MainHeader = ({
  setSearchTerm,
  inProgressStatus,
  selectedPlaylist,
  clearPlaylist,
  toggleMobilePlaylist,
  userData,
  setIsModalOpen,
  setVideos,
  resetToInitialVideos
}) => {
  const handleUpload = () => {
    if (!inProgressStatus) {
      setIsModalOpen(true);
    } else {
      alert('Please wait for the upload to finish.');
    }
  }

  const YouTubeIcon = () => (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>logo--youtube</title>
      <path
        d="M29.41,9.26a3.5,3.5,0,0,0-2.47-2.47C24.76,6.2,16,6.2,16,6.2s-8.76,0-10.94.59A3.5,3.5,0,0,0,2.59,9.26,36.13,36.13,0,0,0,2,16a36.13,36.13,0,0,0,.59,6.74,3.5,3.5,0,0,0,2.47,2.47C7.24,25.8,16,25.8,16,25.8s8.76,0,10.94-.59a3.5,3.5,0,0,0,2.47-2.47A36.13,36.13,0,0,0,30,16,36.13,36.13,0,0,0,29.41,9.26ZM13.2,20.2V11.8L20.47,16Z"
        fill="#FF0000"
      />
      <rect
        id="_Transparent_Rectangle_"
        data-name="&lt;Transparent Rectangle&gt;"
        fill="none"
        width="32"
        height="32"
      />
    </svg>
  );

  const handleRedirect = () => {
    window.open("https://www.youtube.com/playlist?list=PLkl_0nrWH4iGcol0Y-itDyDRk0CffS1Q3", "_blank");
  };

  return (
    <div className="relative mb-8 w-full flex justify-between items-center max600:flex-col-reverse max600:gap-3">
      <SearchBar
        toggleMobilePlaylist={toggleMobilePlaylist}
        onSearchResults={(videoDetails) => {
          if (videoDetails === null) {
            // Clear search, reset to initial videos
            resetToInitialVideos();
          } else if (videoDetails && videoDetails.length > 0) {
            // Set videos to the fetched details
            setVideos(videoDetails);
          }
        }}
      />

      {selectedPlaylist && (
        <div className="flex max-w-[20rem] items-center -mb-6 mr-8 max600:mb-0 max600:mr-0">
          <button
            onClick={clearPlaylist}
            className="text-sm inter text-gray-200 poppins hover:text-gray-800 px-3 py-1 rounded-md bg-gray-800 hover:bg-gray-200 mr-4"
          >
            Clear Playlist
          </button>
          <span className="text-gray-600 poppins text-[0.9rem]">
            Showing: {selectedPlaylist.playlistName} ({selectedPlaylist.videoCount} videos)
          </span>
        </div>
      )}

      <div className='flex gap-12 max600:flex-col max600:w-full max600:gap-3'>
        <div className='flex gap-2 items-center bg-white p-1 px-3 rounded-md font-medium cursor-pointer max600:w-full' onClick={handleRedirect}>Explore all our videos on <YouTubeIcon /></div>
        {userData.userType === 'admin' && (
          <div className="max600:w-full">
            <button
              onClick={handleUpload}
              className="bg-[#f0da15] text-black font-bold tracking-[0.5px] p-2 rounded-md max600:w-full"
            >
              Upload Videos
            </button>
          </div>
        )}
      </div>
    </div>
  );
};