import React, { useContext, useEffect, useState } from "react";
import { API } from "aws-amplify";
import "./userprofile.css";
import Context from "../../Context/Context";
import { useNavigate } from "react-router-dom";

const UserProfile = ({
    isUserAdd,
    updateUserInList,
    isOpen,
    onClose,
    user,
}) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [status, setStatus] = useState("");
    const [balance, setBalance] = useState("");
    const UtilCtx = useContext(Context).util;
    const Ctx = useContext(Context);
    const [cognitoId, setCognitoId] = useState("");
    const Navigate = useNavigate();
    const [lastMonthZPoints, setLastMonthZPoints] = useState("");
    const [currentMonthZPoints, setCurrentMonthZPoints] = useState("");

    // Format date from epoch timestamp
    const formatDate = (epochDate) => {
        if (!epochDate) return "N/A";
        const date = new Date(epochDate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    // Initialize state when user prop changes
    useEffect(() => {
        if (user) {
            setName(user.userName || "");
            setEmail(user.emailId || "");
            setPhoneNumber(user.phoneNumber || "");
            setStatus(user.status || "Active");
            setBalance(user.balance || "");
            setCognitoId(user.cognitoId || "");
            setLastMonthZPoints(user.lastMonthZPoints || "0");
            setCurrentMonthZPoints(user.currentMonthZPoints || "0");
        }
    }, [user]);

    // Check user authorization
    useEffect(() => {
        if (Ctx.isUserDataLoaded) {
            if (Ctx.userData.userType !== "admin") {
                Navigate("/");
            }
        }
    }, [Ctx, Navigate]);

    // Send reminder/invoice
    const sendReminder = async (cognitoId) => {
        UtilCtx.setLoader(true);

        const pa = "Bworkz@ybl";
        const pn = "Bworkz";
        const am = 10;

        try {
            const res = await API.post("user", `/user/send-email/Bworkz`, {
                body: {
                    pa,
                    pn,
                    am,
                    cognitoId,
                },
            });

            console.log(res);
            alert(res.message);
            UtilCtx.setLoader(false);
        } catch (e) {
            console.error("Send reminder error:", e);
            alert("Failed to send reminder");
            UtilCtx.setLoader(false);
        }
    };

    // Update user handler
    const onUpdateUser = async (e) => {
        e.preventDefault();

        // Validate required fields
        const requiredFields = [
            { value: name, message: "Name" },
            { value: email, message: "Email" },
            { value: phoneNumber, message: "Phone Number" },
            { value: status, message: "Status" },
            { value: balance, message: "Balance" }
        ];

        const missingField = requiredFields.find(field => !field.value);
        if (missingField) {
            alert(`Please fill ${missingField.message}`);
            return;
        }

        UtilCtx.setLoader(true);

        try {
            // Prepare update payload - only include fields that have changed
            const updatePayload = {
                cognitoId: cognitoId,
            };

            // Only add fields that have changed
            if (name !== user.userName) updatePayload.userName = name;
            if (email !== user.emailId) updatePayload.emailId = email;
            if (phoneNumber !== user.phoneNumber) updatePayload.phoneNumber = phoneNumber;
            if (status !== user.status) updatePayload.status = status;
            if (balance !== user.balance) updatePayload.balance = balance;
            if (currentMonthZPoints !== user.currentMonthZPoints) updatePayload.currentMonthZPoints = currentMonthZPoints;
            if (lastMonthZPoints !== user.lastMonthZPoints) updatePayload.lastMonthZPoints = lastMonthZPoints;

            // Optional: Add additional fields from context if needed
            updatePayload.renewDate = Ctx.userData.renewDate;
            updatePayload.productId = Ctx.userData.productId;
            updatePayload.subscriptionId = Ctx.userData.subscriptionId;

            // Perform PATCH update
            await API.patch("user", "/admin/update-user", {
                body: updatePayload
            });

            // Update local user list
            const updatedUsers = Ctx.userList.map((item) => 
                item.cognitoId === cognitoId 
                    ? { 
                        ...item, 
                        emailId: email,
                        userName: name,
                        phoneNumber: phoneNumber,
                        status: status,
                        balance: balance,
                        currentMonthZPoints: currentMonthZPoints,
                        lastMonthZPoints: lastMonthZPoints
                      }
                    : item
            );

            Ctx.setUserList(updatedUsers);

            alert("User Updated");
            UtilCtx.setLoader(false);
            onClose();
        } catch (error) {
            console.error("Update user error:", error);
            alert("Failed to update user");
            UtilCtx.setLoader(false);
        }
    };

    // If modal is not open, return null
    if (!isOpen) return null;

    return (
        <div className="modal">
            <div className="modal-content flex items-center justify-center ">
                <div className="">
                    <div className="w-[100%] max1050:w-[100%] max-w-[36rem] bg-[#eceaeae1] rounded-3xl p-3 flex flex-col items-center max536:w-[90%]">
                        <form className="mt-6 flex flex-col gap-8 max560:w-full">
                            {/* Name */}
                            <div className="flex justify-center">
                                <label className="ml-2">Name</label>
                            </div>
                            <div className="flex justify-center">
                                <input
                                    required
                                    placeholder="Name"
                                    className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
                                    type="text"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </div>

                            {/* Email and Phone Number */}
                            <div className="flex flex-row justify-between gap-4 max560:flex-col max560:gap-8">
                                <div className="flex flex-col gap-1 justify-center">
                                    <label className="ml-2">Email</label>
                                    <input
                                        className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
                                        type="email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className="flex flex-col gap-1 justify-center">
                                    <label className="ml-2">Phone Number</label>
                                    <input
                                        className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
                                        type="text"
                                        value={phoneNumber}
                                        onChange={(e) => {
                                            setPhoneNumber(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Attendance and Joining Date */}
                            <div className="flex flex-row justify-between gap-4 max560:flex-col max560:gap-8">
                                <div className="flex flex-col gap-1 justify-center">
                                    <label className="ml-2">Attendance</label>
                                    <div className="flex items-center justify-center w-[70%]">
                                        <input
                                            className="bg-[#c2bfbf81] text-[#0008] px-2 py-2 rounded-lg"
                                            type="number"
                                            value={currentMonthZPoints}
                                            onChange={(e) => {
                                                setCurrentMonthZPoints(e.target.value)
                                            }}
                                        />
                                        <p className="mt-3 mx-2">/</p>
                                        <input
                                            className="bg-[#c2bfbf81] text-[#0008] px-2 py-2 rounded-lg "
                                            type="number"
                                            value={lastMonthZPoints}
                                            onChange={(e) => {
                                                setLastMonthZPoints(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col gap-1 justify-center">
                                    <label className="ml-2">Joining Date</label>
                                    <input
                                        className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
                                        type="text"
                                        readOnly
                                        value={formatDate(user.joiningDate)}
                                    />
                                </div>
                            </div>

                            {/* User Status and Due */}
                            <div className="flex flex-row justify-between gap-4 max560:flex-col max560:gap-8">
                                <div className="flex flex-col gap-1 justify-center">
                                    <label className="ml-2">User Status</label>
                                    <select
                                        className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
                                        value={status}
                                        onChange={(e) => {
                                            setStatus(e.target.value);
                                        }}
                                    >
                                        <option value="Active">Active</option>
                                        <option value="InActive">InActive</option>
                                    </select>
                                </div>

                                <div className="flex flex-col gap-1 justify-center">
                                    <label className="ml-2">Due</label>
                                    <input
                                        className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
                                        type="text"
                                        value={balance}
                                        onChange={(e) => {
                                            setBalance(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="flex justify-center">
                                {/* Send Invoice Button */}
                                <button
                                    className="bg-[#1b7571] text-white rounded-lg py-2 mr-4"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        sendReminder(cognitoId);
                                    }}
                                >
                                    Send Invoice
                                </button>

                                {/* Update Profile Button */}
                                <button
                                    className="bg-[#c2bfbf81] rounded-lg py-2"
                                    onClick={(e) => {
                                        onUpdateUser(e);
                                    }}
                                >
                                    Update Profile
                                </button>
                            </div>

                            {/* Close Button */}
                            <div className="flex justify-center">
                                <button 
                                    className="text-red-500 hover:underline"
                                    onClick={onClose}
                                >
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;