// UsersListMobile.jsx
import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import GoogleSearchIcon from "../../Utils/Assests/icons8-google-web-search-26.png";

const UsersListMobile = ({
    userCheck,
    setUserCheck,
    setIsUserAdd,
    isUserAdd,
    handleToggleUserAdd,
    name,
    setName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    status,
    setStatus,
    balance,
    setBalance,
    onCreateUser,
    searchQuery,
    setSearchQuery,
    userStatus,
    setUserStatus,
    availableStatuses,
    filteredUserList,
    currentPage,
    totalPages,
    setCurrentPage,
    formatDate,
    cognitoId,
    setCognitoId,
    onUpdateUser,
}) => {
    const handleUpdateUser = async (userData) => {
        console.log('handleUpdateUser called with:', userData);
        if (!(userData.name && userData.email && userData.phoneNumber && userData.status && userData.balance)) {
            alert("Fill all Fields");
            return;
        }

        try {
            setName(userData.name);
            setEmail(userData.email);
            setPhoneNumber(userData.phoneNumber);
            setStatus(userData.status);
            setBalance(userData.balance);
            setCognitoId(userData.cognitoId);
            setIsUserAdd(true);
            setUserCheck(2);

            console.log('Calling onUpdateUser with state:', {
                name: userData.name,
                email: userData.email,
                phoneNumber: userData.phoneNumber,
                status: userData.status,
                balance: userData.balance,
                cognitoId: userData.cognitoId
            });

            await onUpdateUser();
            console.log('Update successful');
        } catch (error) {
            console.error('Update failed:', error);
            alert('Update failed: ' + error.message);
        }
    };

    return (
        <div className="w-full px-2 pb-4">
            <div className="container">
                <button
                    className={`filter-button bg-[#FDCF08] mt-0 text-[white] w-full m-[1rem] h-[2.1rem] rounded-lg text-snow ${isUserAdd && userCheck === 1 ? "bg-[#FDCF08]" : ""
                        }`}
                    onClick={handleToggleUserAdd}
                >
                    {isUserAdd && userCheck === 1 ? "Cancel" : "Add New User"}
                </button>
            </div>

            {isUserAdd && userCheck === 1 && (
                <form className="flex flex-col gap-6 w-full Sansita">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-[0.5rem]">
                        <input
                            required
                            placeholder="Name"
                            className="input-field"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input
                            required
                            placeholder="Email Address"
                            className="input-field"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            required
                            className="input-field"
                            placeholder="Phone Number"
                            type="number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <select
                            className="input-field"
                            onChange={(e) => setStatus(e.target.value)}
                            value={status}
                        >
                            <option value="Active">Active</option>
                            <option value="InActive">InActive</option>
                        </select>
                        <input
                            required
                            className="input-field"
                            placeholder="Balance"
                            type="number"
                            value={balance}
                            onChange={(e) => setBalance(e.target.value)}
                        />
                        <div className="flex gap-3 w-full justify-center items-center">
                            <button
                                className="sans-serif tracking-wider bg-[#FDCF08] text-[white] h-[2.4rem] w-[85%] rounded-lg py-2"
                                onClick={onCreateUser}
                            >
                                Create
                            </button>
                        </div>
                    </div>
                </form>
            )}

            <div className="w-full flex justify-center pr-2 mb-3 mt-4">
                <div className="relative">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search by name, email, or phone"
                        style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.4)" }}
                        className="p-1 rounded-[0.3rem] w-[80vw] ml-2"
                    />
                    <span className="absolute right-2 top-1/2 transform -translate-y-1/2">
                        <img
                            src={GoogleSearchIcon}
                            alt="Search Icon"
                            className="h-6 w-6 text-white opacity-80"
                        />
                    </span>
                </div>
            </div>

            <div className="flex">
                <div className="w-[95%] flex justify-end gap-3">
                    <label className="font-bold" htmlFor="userStatusFilter">
                        User Status:
                    </label>
                    <select
                        className="rounded-[0.51rem] mr-3 py-1 px-1 bg-snow"
                        id="userStatusFilter"
                        value={userStatus}
                        onChange={(e) => setUserStatus(e.target.value)}
                        style={{ boxShadow: "0 0 12px rgba(0, 0, 0, 0.3)" }}
                    >
                        {availableStatuses.map((status) => (
                            <option key={status} value={status} style={{ background: "white" }}>
                                {status === "all" ? "All" : status}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <h2 className="text-[1.4rem] mb-5 font-bold text-black-700 mt-10 text-center">
                Members List
            </h2>

            <div className="grid gap-[1.5rem] md:gap-4 grid-cols-1 sm:grid-cols-2">
                {filteredUserList.map((user) => (
                    <div
                        key={user.cognitoId}
                        className="bg-gradient-to-r from-[#1b7571] to-[#1b7571] rounded-lg shadow-md p-3 md:p-4"
                        style={{
                            background: "#eceaeaa1",
                            boxShadow: "0 0px 15px rgba(0, 0, 0, 0.4)",
                            borderRadius: "1.5rem",
                        }}
                    >
                        {isUserAdd && userCheck === 2 && cognitoId === user.cognitoId ? (
                            <UserUpdateForm
                                name={name}
                                setName={setName}
                                email={email}
                                setEmail={setEmail}
                                phoneNumber={phoneNumber}
                                setPhoneNumber={setPhoneNumber}
                                status={status}
                                setStatus={setStatus}
                                balance={balance}
                                setBalance={setBalance}
                                cognitoId={cognitoId}
                                onUpdateUser={onUpdateUser}
                                setIsUserAdd={setIsUserAdd}
                                setUserCheck={setUserCheck}
                            />
                        ) : (
                            <UserCard
                                user={user}
                                formatDate={formatDate}
                                setIsUserAdd={setIsUserAdd}
                                setCognitoId={setCognitoId}
                                setName={setName}
                                setEmail={setEmail}
                                setPhoneNumber={setPhoneNumber}
                                setStatus={setStatus}
                                setBalance={setBalance}
                                setUserCheck={setUserCheck}
                                onUpdateUser={handleUpdateUser}
                            />
                        )}
                    </div>
                ))}
            </div>

            <div className="flex mb-[6rem] justify-center items-center mt-4 md:mt-6">
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                />
            </div>
        </div>
    );
};

const UserUpdateForm = ({
    name,
    setName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    status,
    setStatus,
    balance,
    setBalance,
    cognitoId,
    onUpdateUser,
    setIsUserAdd,
    setUserCheck,
}) => {
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await onUpdateUser();
            setIsUserAdd(false);
            setUserCheck(0);
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to update user: ' + error.message);
        }
    };

    return (
        <form className="flex flex-col gap-[1rem] w-full Sansita">
            <input
                required
                placeholder="Name"
                className="bg-[#eceaeaa1] text-[#00000064] p-[4px] pl-[1rem] rounded-[0.3rem] item-center"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <input
                required
                placeholder="Email"
                className="bg-[#eceaeaa1] text-[#00000064] p-[4px] pl-[1rem] rounded-[0.3rem]"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                required
                placeholder="Phone"
                className="bg-[#eceaeaa1] text-[#00000064] p-[4px] pl-[1rem] rounded-[0.3rem]"
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <select
                className="bg-[#eceaeaa1] text-[#00000064] p-[4px] pl-[1rem] rounded-[0.3rem]"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
            >
                <option value="Active">Active</option>
                <option value="InActive">InActive</option>
            </select>
            <input
                required
                placeholder="Balance"
                className="bg-[#eceaeaa1] text-[#00000064] p-[4px] pl-[1rem] rounded-[0.3rem]"
                type="number"
                value={balance}
                onChange={(e) => setBalance(e.target.value)}
            />
            <div className="flex gap-3 w-full items-center justify-center">
                <button
                    className="sans-sarif bg-[#FDCF08] text-[white] w-[41vw] tracking-wide rounded-[0.2rem] py-1 mb-4"
                    onClick={() => {
                        setIsUserAdd(false);
                        setUserCheck(0);
                    }}
                >
                    Cancel
                </button>
                <button
                    className="sans-sarif bg-[#FDCF08] text-[white] w-[41vw] tracking-wide rounded-[0.2rem] py-1 mb-4"
                    onClick={handleSubmit}
                >
                    Update
                </button>
            </div>
        </form>
    );
};

const UserCard = ({
    user,
    formatDate,
    setIsUserAdd,
    setCognitoId,
    setName,
    setEmail,
    setPhoneNumber,
    setStatus,
    setBalance,
    setUserCheck,
    onUpdateUser
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [localName, setLocalName] = useState(user.userName);
    const [localEmail, setLocalEmail] = useState(user.emailId);
    const [localPhone, setLocalPhone] = useState(user.phoneNumber);
    const [localStatus, setLocalStatus] = useState(user.status);
    const [localBalance, setLocalBalance] = useState(user.balance);

    const handleViewProfile = () => {
        setIsModalOpen(true);
    };

    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
        console.log('Submitting update with data:', {
            cognitoId: user.cognitoId,
            name: localName,
            email: localEmail,
            phoneNumber: localPhone,
            status: localStatus,
            balance: localBalance
        });

        try {
            await onUpdateUser({
                cognitoId: user.cognitoId,
                name: localName,
                email: localEmail,
                phoneNumber: localPhone,
                status: localStatus,
                balance: localBalance
            });
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error in handleUpdateSubmit:', error);
        }
    };

    return (
        <>
            <div className="flex items-center justify-between">
                <h3 className="text-lg font-bold mb-2">{user.userName}</h3>
            </div>
            <div className="mb-1">Email: {user.emailId}</div>
            <div>Phone: {user.phoneNumber}</div>
            <div className="mb-1">Country: {user.country}</div>
            <div className="mb-1">Joining Date: {formatDate(user.joiningDate)}</div>
            <div className="mb-1">Due: {user.balance}</div>
            <div className="mb-1">Status: {user.status}</div>
            <div className="mb-1">
                Attendance: {user.currentMonthZPoints || 0}/{user.lastMonthZPoints || 0}
            </div>
            <button
                onClick={handleViewProfile}
                className="bg-white rounded-[0.3rem] px-3 py-1 text-black font-bold text-center mt-2 w-[87vw]"
            >
                View Profile
            </button>

            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white rounded-lg p-4 w-[90%] max-w-md">
                        <form className="flex flex-col gap-[1rem] w-full Sansita">
                            <input
                                required
                                placeholder="Name"
                                className="bg-[#eceaeaa1] text-[#00000064] p-[4px] pl-[1rem] rounded-[0.3rem] item-center"
                                type="text"
                                value={localName}
                                onChange={(e) => setLocalName(e.target.value)}
                            />
                            <input
                                required
                                placeholder="Email"
                                className="bg-[#eceaeaa1] text-[#00000064] p-[4px] pl-[1rem] rounded-[0.3rem]"
                                type="email"
                                value={localEmail}
                                onChange={(e) => setLocalEmail(e.target.value)}
                            />
                            <input
                                required
                                placeholder="Phone"
                                className="bg-[#eceaeaa1] text-[#00000064] p-[4px] pl-[1rem] rounded-[0.3rem]"
                                type="tel"
                                value={localPhone}
                                onChange={(e) => setLocalPhone(e.target.value)}
                            />
                            <select
                                className="bg-[#eceaeaa1] text-[#00000064] p-[4px] pl-[1rem] rounded-[0.3rem]"
                                value={localStatus}
                                onChange={(e) => setLocalStatus(e.target.value)}
                            >
                                <option value="Active">Active</option>
                                <option value="InActive">InActive</option>
                            </select>
                            <input
                                required
                                placeholder="Balance"
                                className="bg-[#eceaeaa1] text-[#00000064] p-[4px] pl-[1rem] rounded-[0.3rem]"
                                type="number"
                                value={localBalance}
                                onChange={(e) => setLocalBalance(e.target.value)}
                            />
                            <div className="flex gap-3 w-full items-center justify-center">
                                <button
                                    className="sans-sarif bg-[#FDCF08] text-white w-1/2 tracking-wide rounded-[0.2rem] py-1"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="sans-sarif bg-[#FDCF08] text-white w-1/2 tracking-wide rounded-[0.2rem] py-1"
                                    onClick={handleUpdateSubmit}
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default UsersListMobile;